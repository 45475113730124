import { useProfileMeLazyQuery } from 'web-queries/src/types/generated'
import { getUserToken, loadUserToken, signOut } from 'web-queries/src/utils/user'
import isString from 'lodash-es/isString'
import { useEffect } from 'react'
import useUserContext from '../hooks/useUserContext'

interface Props {
  children: JSX.Element
}

export default function AppWrapper({ children }: Props) {
  const [queryProfileMe, { data, loading, called, error }] = useProfileMeLazyQuery()

  const { ActionTypes, dispatch } = useUserContext()

  useEffect(() => {
    // Note: context로 넣어둔 header가 lazy query에서 뜬금없이 안 먹기 시작해서
    // setTimeout으로 한번 감쌌음. 왜 헤더가 누락되는지 원인 파악해야함.
    loadUserToken()

    dispatch({
      type: ActionTypes.AUTHENTICATE,
    })

    const token = getUserToken()

    if (isString(token)) {
      const context = {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('indistreet_jwt')}`,
        },
      }

      queryProfileMe({
        context,
      })
    } else {
      dispatch({
        type: ActionTypes.AUTHENTICATE_CANCEL,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data && !loading && called) {
      dispatch({
        type: ActionTypes.AUTHENTICATE_SUCCESS,
        payload: data.me,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, called])

  useEffect(() => {
    if (error) {
      dispatch({
        type: ActionTypes.AUTHENTICATE_CANCEL,
      })
      signOut()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])
  return <>{children}</>
}
