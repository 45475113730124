import { UserState } from 'web-queries/src/types/generated'
import React, { createContext, useReducer, FC } from 'react'

const ActionTypes = {
  AUTHENTICATE_SUCCESS: 'AUTHENTICATE_SUCCESS',
  AUTHENTICATE_CANCEL: 'AUTHENTICATE_CANCEL',
  AUTHENTICATE: 'AUTHENTICATE',
  USER_INFO_UPDATE_SUCCESS: 'USER_INFO_UPDATE_SUCCESS',
  USER_INFO_UPDATE_FAILED: 'USER_INFO_UPDATE_FAILED',
} as const

type Action = {
  type: string
  payload?: any
}

const initializeState: UserState & {
  isAuthenticated: boolean
  isContentAdmin: boolean
  isLoading: boolean
  isInitialized: false
} = {
  id: null,
  username: '',
  confirmed: false,
  blocked: false,
  role: null,
  isInitialized: false,
  isAuthenticated: false,
  isContentAdmin: false,
  isLoading: false,
  editableMusicians: [],
  editableRecordLabels: [],
  editableStages: [],
}

const reducer = (state: UserState = initializeState, action: Action) => {
  switch (action.type) {
    case ActionTypes.AUTHENTICATE:
      return {
        ...state,
        isLoading: true,
        isInitialized: false,
      }
    case ActionTypes.AUTHENTICATE_SUCCESS:
      return {
        ...action.payload,
        isContentAdmin: action.payload.role?.name === 'ContentAdmin',
        isAuthenticated: true,
        isLoading: false,
        isInitialized: true,
      }
    case ActionTypes.AUTHENTICATE_CANCEL:
      return {
        ...initializeState,
        isInitialized: true,
      }
    default:
      return state
  }
}

export const UserContext = createContext({
  ...initializeState,
  ActionTypes,
  // eslint-disable-next-line
  dispatch: (action: Action) => {},
})

export const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initializeState)
  const value = {
    ...state,
    ActionTypes,
    dispatch,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
