import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useMemo } from "react";
import { trimEnd } from "lodash";
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import fragmentTypes from "../fragmentTypes.json";
import typePolicies from "../types/typePolicies";
import { loadUserToken, getUserToken } from "../utils/user";
var apolloClient = null;
function createApolloClient() {
    // Note: import 할 때 hould not import the named export 'possibleTypes' (imported as 'possibleTypes') from default-exporting module (only default export is available soon)
    // 이런 에러가 나서 이렇게 꺼내서 쓰도록 함
    var possibleTypes = fragmentTypes.possibleTypes;
    loadUserToken();
    var authLink = setContext(function(query, param) {
        var headers = param.headers;
        if (query.operationName === "ProfileMe") {
            // get the authentication token from local storage if it exists
            var token = getUserToken();
            // return the headers to the context so httpLink can read them
            return {
                headers: _object_spread_props(_object_spread({}, headers), {
                    authorization: token ? "Bearer ".concat(token) : ""
                })
            };
        }
    });
    var httpLink = new HttpLink({
        uri: "".concat(trimEnd(process.env.NEXT_PUBLIC_API_ENDPOINT, "/"), "/graphql")
    });
    return new ApolloClient({
        ssrMode: "object" === "undefined",
        link: authLink.concat(httpLink),
        cache: new InMemoryCache({
            possibleTypes: possibleTypes,
            typePolicies: typePolicies
        }),
        /**
     * NOTE: For using apollo devtools/
     * https://www.apollographql.com/docs/react/development-testing/developer-tooling/#apollo-client-devtools
     */ connectToDevTools: "production" !== "production"
    });
}
export function initializeApollo(options) {
    var _apolloClient = apolloClient !== null && apolloClient !== void 0 ? apolloClient : createApolloClient();
    var ref;
    var initialState = (ref = options === null || options === void 0 ? void 0 : options.initialState) !== null && ref !== void 0 ? ref : null;
    // If your page has Next.js data fetching methods that use Apollo Client,
    // the initial state gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        var existingCache = _apolloClient.extract();
        // Restore the cache using the data passed from
        // getStaticProps/getServerSideProps combined with the existing cached data
        _apolloClient.cache.restore(_object_spread({}, existingCache, initialState));
    }
    // For SSG and SSR always create a new Apollo Client
    if ("object" === "undefined") return _apolloClient;
    // Create the Apollo Client once in the client
    if (!apolloClient) apolloClient = _apolloClient;
    return _apolloClient;
}
export function useApollo(options) {
    var ref;
    var initialState = (ref = options === null || options === void 0 ? void 0 : options.initialState) !== null && ref !== void 0 ? ref : null;
    var store = useMemo(function() {
        return initializeApollo({
            initialState: initialState
        });
    }, [
        initialState
    ]);
    return store;
}
