var TOKEN_KEY = "indistreet_jwt";
var USERNAME_KEY = "indistreet_username";
var token = null;
var username = null;
export var loadUserToken = function() {
    if ("object" !== "undefined") {
        token = window.localStorage.getItem(TOKEN_KEY);
        username = window.localStorage.getItem(USERNAME_KEY);
    }
};
export var getUserToken = function() {
    return token;
};
export var isLoggedIn = function() {
    return !!token;
};
export var setUserToken = function(token, username) {
    if ("object" !== "undefined") {
        window.localStorage.setItem(TOKEN_KEY, token);
        window.localStorage.setItem(USERNAME_KEY, username);
        loadUserToken();
    }
};
export var EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export var signOut = function() {
    if ("object" !== "undefined") {
        window.localStorage.removeItem(TOKEN_KEY);
        window.localStorage.removeItem(USERNAME_KEY);
    }
};
