
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import './_app.scss'

import { AppProps } from 'next/app'
import { useEffect } from 'react'
import AppWrapper from '../components/AppWrapper'
import { ApolloProvider } from '@apollo/client'
import { useApollo } from 'web-queries/src/utils/apolloClient'
import Router, { useRouter } from 'next/router'
import NProgress from 'nprogress'
import * as gtag from '../utils/gtag'

// import * as Sentry from '@sentry/node'
// import { RewriteFrames } from '@sentry/integrations'
import { ChakraProvider } from '@chakra-ui/react'
import { UserContextProvider } from '../contexts/UserContext'
import { LoadingContextProvider } from '../contexts/LoadingContext'
/*
if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const integrations = []

  if (process.env.NEXT_IS_SERVER === 'true' && process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR) {
    // For Node.js, rewrite Error.stack to use relative paths, so that source
    // maps starting with ~/_next map to files in Error.stack with path
    // app:///_next
    integrations.push(
      new RewriteFrames({
        iteratee: frame => {
          if (process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR) {
            frame.filename = frame?.filename?.replace(process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR, 'app:///')
            frame.filename = frame?.filename?.replace('.next', '_next')
          }
          return frame
        },
      })
    )
  }

  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    integrations,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: process.env.NEXT_PUBLIC_COMMIT_SHA,
  })
}*/

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const __Page_Next_Translate__ = function App({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const apolloClient = useApollo({
    initialState: pageProps.initialApolloState,
  })

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const handleRouteChange = (url: URL) => {
        gtag.pageview(url)
      }
      router.events.on('routeChangeComplete', handleRouteChange)
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange)
      }
    }
  }, [router.events])

  return (
    <ApolloProvider client={apolloClient}>
      <ChakraProvider resetCSS>
        <UserContextProvider>
          <LoadingContextProvider>
            <AppWrapper>
              <Component {...pageProps} />
            </AppWrapper>
          </LoadingContextProvider>
        </UserContextProvider>
      </ChakraProvider>
    </ApolloProvider>
  )
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  