import {
  Box,
  CircularProgress,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import React, { createContext, useState } from 'react'

interface LoadingContextProps {
  show: (message: string | React.ReactNode) => void
  hide: () => void
}
export const LoadingContext = createContext<LoadingContextProps>({
  show: (_: string | React.ReactNode) => {},
  hide: () => {},
})

const DEFAULT_LOADING_MESSAGE = '저장 중입니다..'

export const LoadingContextProvider = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [message, setMessage] = useState<string | React.ReactNode>(DEFAULT_LOADING_MESSAGE)

  return (
    <LoadingContext.Provider
      value={{
        show: (message?: string) => {
          if (message) {
            setMessage(message)
          }
          onOpen()
        },
        hide: () => {
          setMessage(DEFAULT_LOADING_MESSAGE)
          onClose()
        },
      }}
    >
      <Modal isCentered isOpen={isOpen} onClose={() => {}}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Box padding="24px">
              <Flex flexDir="row">
                <CircularProgress isIndeterminate color="red.500" />
                <Box marginLeft="12px" paddingTop="16px">
                  {message}
                </Box>
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      {children}
    </LoadingContext.Provider>
  )
}
