import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { isNil } from "lodash";
var read = function(existing, param) {
    var _args = param.args, start = _args.start, limit = _args.limit;
    if (isNil(start) && isNil(limit)) {
        return existing;
    }
    return existing;
};
var merge = function(existing, incoming, param) {
    var start = param.args.start;
    var merged = existing ? _to_consumable_array(existing) : [];
    var startIndex = start !== null && start !== void 0 ? start : 0;
    for(var i = 0; i < incoming.length; i++){
        merged[startIndex + i] = incoming[i];
    }
    return merged;
};
var fields = {
    musicians: {
        keyArgs: [
            "sort",
            "limit",
            "where"
        ],
        read: read,
        merge: merge
    },
    lives: {
        keyArgs: [
            "sort",
            "limit",
            "where"
        ],
        read: read,
        merge: merge
    },
    albums: {
        keyArgs: [
            "sort",
            "limit",
            "where"
        ],
        read: read,
        merge: merge
    },
    stages: {
        keyArgs: [
            "sort",
            "limit",
            "where"
        ],
        read: read,
        merge: merge
    }
};
var typePolicies = {
    Query: {
        fields: fields
    }
};
export default typePolicies;
